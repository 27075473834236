<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
            <!--            <b-alert-->
            <!--                style="margin-top: 5px"-->
            <!--                variant="primary"-->
            <!--                show-->
            <!--            >-->
            <!--              <div class="alert-body">-->
            <!--                <span><strong>已勾选:{{-->
            <!--                    checked.length-->
            <!--                  }}  合计退货数量:{{ checked.reduce((sum, e) => sum + Number(e.item_count || 0), 0) }}</strong> </span>-->
            <!--              </div>-->
            <!--            </b-alert>-->
          </b-col>
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-success"
                  class="mr-1"
                  @click="showAddRelation"
                  style="margin: 5px;"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">新增</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-info"
                  class="mr-1"
                  @click="advanced_search_excel"
                  style="margin: 5px;"
                  v-if=""
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">导入</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-1"
                  @click="exportList"
                  style="margin: 5px;"
                  v-if=""
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">导出</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
                  class="mr-1"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-form>
          <b-card
              v-show="advanced_search_modal_excel"
          >

            <b-row>
              <!--excel-->
<!--              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="仓库名称"
                    label-for="label"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <v-select
                      id="otherWarehouseId"
                      :options="salesWarehouseArray.length===0?[]:salesWarehouseArray"
                      :clearable="true"
                      class="select-size-sm"
                      v-model="state.condition.otherWarehouseName"
                      @input="changeSelect('otherWarehouseId',$event)"
                      placeholder="请选择仓库"
                  />
                </b-form-group>
&lt;!&ndash;                <xy-input-button&ndash;&gt;
&lt;!&ndash;                    label="仓库"&ndash;&gt;
&lt;!&ndash;                    type="input"&ndash;&gt;
&lt;!&ndash;                    v-on:change="fromChildren($event,['otherWarehouseName','otherWarehouseId'])"&ndash;&gt;
&lt;!&ndash;                    :params="['otherWarehouseName','otherWarehouseId']"&ndash;&gt;
&lt;!&ndash;                    :value="state.condition.otherWarehouseName"&ndash;&gt;
&lt;!&ndash;                    modalName="仓库"&ndash;&gt;
&lt;!&ndash;                    placeholder="点击选择仓库"&ndash;&gt;
&lt;!&ndash;                >&ndash;&gt;
&lt;!&ndash;                </xy-input-button>&ndash;&gt;
              </b-col>-->
              <b-col md="10">
                <b-form-group
                    label-cols="1"
                    label-cols-lg="1"
                    label="相关附件"
                    label-for="attachments_ids"
                    label-size="sm"
                    class="mb-1"
                >

                  <attachment-upload :theme="'files'"
                                     :object_type="'excel'"
                                     @change="onUploaded"
                  />
                </b-form-group>
              </b-col>

              <!--导入关闭-->
              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-success"
                    @click="importRelation"
                    v-if="importShow"
                >
                  <span class="align-right">导入</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search_excel"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
        <b-card
            v-show="advanced_search_modal"
        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >

            <b-row>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="仓库名称"
                    label-for="label"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <v-select
                      id="warehouse_id"
                      :options="salesWarehouseArray.length===0?[]:salesWarehouseArray"
                      :clearable="true"
                      class="select-size-sm"
                      v-model="warehouseName"
                      @input="changeSelect('warehouse_id',$event)"
                      placeholder="请选择仓库"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="我方ID:"
                    label-for="productId"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="state.condition.productId"
                      name="productId"
                      class="d-inline-block mr-1"
                      size="sm"
                      type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="我方69码:"
                    label-for="code"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="state.condition.code"
                      name="code"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="我方商品:"
                    label-for="productName"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="state.condition.productName"
                      name="ProductName"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="外部69码:"
                    label-for="outCode"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="state.condition.outCode"
                      name="outCode"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="外部商品:"
                    label-for="outProductName"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="state.condition.outProductName"
                      name="outProductName"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-success"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-warning"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>

              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

      <!--  新增按钮弹窗    -->
      <b-modal
          id="modelNew"
          cancel-variant="outline-secondary"
          ok-title="确定"
          @ok="saveRelation"
          cancel-title="取消"
          @cancel="cancelRelation"
          @close="cancelRelation"
          ref="modelNew"
          centered
          size="lg"
          title="新增商品映射"
      >

        <b-form>
          <b-row>
            <b-col
                cols="12"
                md="6"
            >
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="仓库名称"
                  label-for="label"
                  label-size="sm"
                  class="mb-1 required"
              >
                <v-select
                    id="other_warehouse_id"
                    :options="salesWarehouseArray.length===0?[]:salesWarehouseArray"
                    :clearable="true"
                    class="select-size-sm"
                    v-model="newWarehouseName"
                    @input="changeSelect('other_warehouse_id',$event)"
                    placeholder="请选择仓库"
                    :disabled="prwId !== 0"
                />
              </b-form-group>
            </b-col>
            <b-col
                cols="12"
                md="6">
            </b-col>
            <b-col
                cols="12"
                md="6"
            >
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="我方69码:"
                  label-for="ourCode"
                  label-size="sm"
              >
                <b-form-input
                    v-model="ourCode"
                    name="ourCode"
                    class="d-inline-block mr-1"
                    size="sm"
                    :disabled="prwId !== 0"
                />
              </b-form-group>
            </b-col>
            <b-col
                cols="12"
                md="6"
            >
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="我方名称:"
                  label-for="ProductName"
                  label-size="sm"
              >
                <b-form-input
                    v-model="productName"
                    name="productName"
                    class="d-inline-block mr-1"
                    size="sm"
                    :disabled="prwId !== 0"
                />
              </b-form-group>
            </b-col>
            <b-col
                cols="12"
                md="6"
            >
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="外部69码:"
                  label-for="outProductId"
                  label-size="sm"
              >
                <b-form-input
                    v-model="outCode"
                    name="outProductId"
                    class="d-inline-block mr-1"
                    size="sm"
                />
              </b-form-group>
            </b-col>
            <b-col
                cols="12"
                md="6"
            >
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="外部名称:"
                  label-for="outProductName"
                  label-size="sm"
              >
                <b-form-input
                    v-model="outProductName"
                    name="productName"
                    class="d-inline-block mr-1"
                    size="sm"
                />
              </b-form-group>
            </b-col>
            <b-col
                cols="12"
                md="6">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="托规:"
                  label-for="rule"
                  label-size="sm"
              >
                <b-form-input
                    v-model="rule"
                    name="rule"
                    class="d-inline-block mr-1"
                    size="sm"
                    type="number"
                />
              </b-form-group>
            </b-col>
            <b-col
                    cols="12"
                    md="6" v-if="prwId !== 0">
              <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="毛重/G:"
                      label-for="grossWeight"
                      label-size="sm"
              >
                <b-form-input
                        v-model="grossWeight"
                        name="grossWeight"
                        class="d-inline-block mr-1"
                        size="sm"
                        type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>

      <div v-show="show_import"><xy-loading ></xy-loading>  </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <!--        <template #cell(product_id)="data">-->
        <!--          {{ getCodeLabel('product', data.item.product_id) }}-->
        <!--        </template>-->
        <template #cell(productName)="data">
          {{ data.item.ext.productName }}
        </template>
        <template #cell(grossWeight)="data">
          {{ data.item.ext.grossWeight }}
        </template>

        <template #cell(productCode)="data">
          {{ data.item.ext.productCode }}
        </template>

        <template #cell(warehouse_id)="data">
          {{ getCodeLabel('warehouse', data.item.warehouse_id) }}
        </template>


        <template #cell(create_time)="data">
          {{ toDate(data.item.create_time) }}
        </template>

        <template #cell(update_time)="data">
          {{ toDate(data.item.update_time) }}
        </template>

        <template #cell(creator)="data">
          {{ getCodeLabel('user', data.item.creator) }}
        </template>

        <template #cell(updator)="data">
          {{ getCodeLabel('user', data.item.updator) }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <!--多菜单-->
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="editRelation(data.item.prw_id)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

            <b-dropdown-item @click="delRelation(data.item.prw_id)">
              <feather-icon icon="XSquareIcon"/>
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted, reactive, onMounted} from '@vue/composition-api'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor} from '@core/utils/filter'
import productrelationidwarehouseUseList from './productrelationidwarehouseUseList'
import productrelationidwarehouseStore from './productrelationidwarehouseStore'
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import XyInputButton from "@/views/components/xy/XyInputButton";
import warehouseStore from "@/views/apps/warehouse/warehouseStore";
import Ripple from "vue-ripple-directive";
import productStore from "@/views/apps/product/productStore";
import {getUserData} from "@/auth/utils";
import {useToast} from "vue-toastification/composition";
import userStore from "@/views/apps/user/userStore";
import XyLoading from "@/views/components/xy/XyLoading";
import axios from "@/libs/axios";

export default {

  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    AttachmentUpload,
    XyInputButton,
    XyLoading,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      advanced_search_modal: false,
      advanced_search_modal_excel: false,
      show_import: false,
      showOr: true,
      importShow: true,
      isNew: 0,
      productRelationidWarehouse: {},
      checked: [],
      prwId: 0,
      productName: '',
      warehouseId: 0,
      productId: 0,
      code: '',
      outProductId: 0,
      ourCode: '',
      outCode: '',
      excelSqlId: '',
      outProductName: '',
      salesWarehouseArray: [],
      warehouseName: '',
      otherWarehouseName: '',
      otherWarehouseId: 0,
      newWarehouseName: '',
      newWarehouseId: 0,
      salesWarehouse: {},
      rule: '',
      grossWeight: ''
    }
  },

  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('productrelationidwarehouse/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    exportList: function () {
      const condition = this.state.condition
      axios({
            url: '/api/exportexcelfile/createProductRelationExcel',
            method:'get',
            params: {
              search_key: this.searchKey,
              prwId: condition.prwId,
              warehouseId: condition.warehouseId,
              productId: condition.productId,
              code: condition.code,
              productName: condition.productName,
              outProductId: condition.outProductId,
              outProductName: condition.outProductName,
              outCode: condition.outCode,
              order_by: this.orderBy,
              order_desc: this.isSortDirDesc === true ? 'desc':'',
            },
            responseType: 'blob',
          }
      ).then(res => {
        let data = new Blob([res.data], { type: 'application/vnd.ms-excel' })   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = "物料映射-"+new Date().getTime();
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data);
        link.setAttribute('download',filename+'.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },
    searchByCondition() {
      this.refetchData()
    },
    resetCondition() {
      this.state.condition = {}
      this.warehouseName = ''
      store.commit('productrelationidwarehouse/updateCondition', this.state.condition)
      this.refetchData();
    }
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('productrelationidwarehouse')) store.registerModule('productrelationidwarehouse', productrelationidwarehouseStore)
    if (!store.hasModule('warehouse')) store.registerModule('warehouse', warehouseStore)
    if (!store.hasModule('product')) store.registerModule('product', productStore)
    if (!store.hasModule('user')) store.registerModule('user', userStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('productrelationidwarehouse')) store.unregisterModule('productrelationidwarehouse')
      if (store.hasModule('warehouse')) store.unregisterModule('warehouse')
      if (store.hasModule('product')) store.unregisterModule('product')
      if (store.hasModule('user')) store.unregisterModule('user')
    })

    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }

    const advanced_search_excel = function () {
      this.advanced_search_modal_excel = this.advanced_search_modal_excel ? false : true;
    }

    // const fromChildren = function (params, modal) {
    //   for (let i = 0; i < modal.length; i++) {
    //     this.state.condition[modal[i]] = params == null ? null : params[modal[i]]
    //   }
    //   this.$forceUpdate()
    // }

    const showAddRelation = function () {
      this.$refs['modelNew'].show()
    }

    const getWarehouse = function () {
      store.dispatch('warehouse/search', this.salesWarehouse).then(res => {
        if (res.data.code === 0) {
          let data = res.data.data.list
          for (let i = 0; i < data.length; i++) {
            this.salesWarehouseArray.push({"label": data[i].warehouse_name, "value": data[i].warehouse_id})
          }
        } else {
          toast.error('仓库列表加载失败')
        }
      })
    }

    const findWarehouse = function () {
      store.dispatch('warehouse/findWarehouseByUserId', {
        userId: this.user.user_id
      }).then(res => {
        if (res.data.code === 0) {
          console.log(res)
          let data = res.data.data.list
          for (let i = 0; i < data.length; i++) {
            this.salesWarehouseArray.push({"label": data[i].warehouse_name, "value": data[i].warehouse_id})
          }
        } else {
          toast.error('仓库加载失败')
        }
      })
    }

    const editRelation = function (item) {
      this.$refs['modelNew'].show()
      if (item !== null) {
        store.dispatch('productrelationidwarehouse/search', {
          prwId: item
        }).then(res => {
          if (res.data.code === 0) {
            store.dispatch('product/getProductById', {product_id: res.data.data.list[0].product_id}).then(res => {
              if (res.data.code === 0) {
                this.productName = res.data.data.name
                this.ourCode = res.data.data.code
              } else {
                toast.error('我方商品信息加载失败')
              }
            })
            this.prwId = res.data.data.list[0].prw_id
            this.outCode = res.data.data.list[0].out_code
            this.outProductName = res.data.data.list[0].out_product_name
            this.newWarehouseId = res.data.data.list[0].warehouse_id
            this.rule = res.data.data.list[0].rule
            this.grossWeight = res.data.data.list[0].ext.grossWeight
            this.newWarehouseName = getCodeLabel("warehouse", res.data.data.list[0].warehouse_id)
          } else {
            toast.error('映射加载失败')
          }
        })
      }
    }

    const delRelation = function (item) {
      if (confirm("确定删除这条数据吗？")) {
        store.dispatch('productrelationidwarehouse/delRelation', {prwId: item}).then(res => {
          if (res.data.code === 0) {
            this.refetchData()
            toast.success(res.data.data)
          } else {
            toast.error('删除失败')
          }
        })
      }
    }

    const changeSelect = function (key, event) {
      if (event !== null) {
        if (key === 'warehouse_id') {
          this.state.condition.warehouseName = event.label
          this.state.condition.warehouseId = event.value
        }
        if (key === 'other_warehouse_id') {
          this.newWarehouseName = event.label
          this.newWarehouseId = event.value
        }
        if (key === 'otherWarehouseId') {
          this.state.condition.otherWarehouseName = event.label
          this.state.condition.otherWarehouseId = event.value
        }
      }else{
        if (key === 'warehouse_id') {
          this.state.condition.warehouseName = undefined
          this.state.condition.warehouseId = undefined
        }
        if (key === 'other_warehouse_id') {
          this.newWarehouseName = undefined
          this.newWarehouseId = undefined
        }
        if(key === 'otherWarehouseId'){
          this.state.condition.otherWarehouseName = undefined
          this.state.condition.otherWarehouseId = undefined
        }
      }
    }

    const onUploaded = function (id, attachment, result) {
      this.excelSqlId = result
    }

    const importRelation = function () {
      this.show_import = true
      this.importShow = false
      /*if (this.state.condition.otherWarehouseId === undefined || this.state.condition.otherWarehouseId === 0 ){
        this.importShow = true
        this.show_import = false
        toast.error('请选择仓库')
        return false
      }*/
      if(this.excelSqlId === undefined || this.excelSqlId === ''){
        this.importShow = true
        this.show_import = false
        toast.error('请添加附件')
        return false
      }
      const params = {
        //warehouseId: this.state.condition.otherWarehouseId,
        excelSqlId: this.excelSqlId,
      }
      store.dispatch('productrelationidwarehouse/exportRelation', params)
          .then(response => {
            const data = response.data.data
            if (response.data.code == 0) {
              this.importShow = true
              this.show_import = false
              toast.success(data)
              this.refetchData()
            } else {
              this.importShow = true
              this.show_import = false
              toast.error(data)
            }
          })
    }

    // 关闭弹窗清空表单
    const cancelRelation = function () {
      this.newWarehouseId = 0
      this.prwId = 0
      this.productRelationidWarehouse = {}
      this.newWarehouseName = ''
      this.ourCode = ''
      this.productName = ''
      this.outCode = ''
      this.outProductName = ''
      this.rule = ''
      this.grossWeight = ''
    }

    const saveRelation = function () {
      if (this.newWarehouseId === 0) {
        toast.error("请选择 仓库!")
        return false
      }
      if (this.ourCode === '') {
        toast.error("请填写 我方69码!")
        return false
      }
      if (this.productName === '') {
        toast.error("请填写 我方商品名称!")
        return false
      }
      if (this.outCode === '') {
        toast.error("请填写 外部69码!")
        return false
      }
      if (this.outProductName === '') {
        toast.error("请填写 外部商品名称!")
        return false
      }
      if (this.rule < 0) {
        toast.error("托规不能为负数!")
        return false
      }
      if (this.grossWeight < 0) {
        toast.error("重量不能为负数!")
        return false
      }
      if (this.prwId === 0) {
        this.isNew = 1
      } else {
        this.isNew = 0
      }
      store.dispatch('product/getProductId', {
        isNew: this.isNew,
        ourCode: this.ourCode,
        productName: this.productName
      }).then(res => {
        if (res.data.code === 0 && res.data.data.length > 0) {
          if (this.prwId !== 0) {
            this.productRelationidWarehouse.prw_id = this.prwId
          }
          this.productRelationidWarehouse.product_id = res.data.data[0].product_id
          this.productRelationidWarehouse.warehouse_id = this.newWarehouseId
          this.productRelationidWarehouse.out_code = this.outCode
          this.productRelationidWarehouse.out_product_name = this.outProductName
          this.productRelationidWarehouse.rule = this.rule
          this.productRelationidWarehouse.grossWeight = this.grossWeight
          store.dispatch('productrelationidwarehouse/save', this.productRelationidWarehouse).then(res => {
            if (res.data.code === 0) {
              if (this.prwId === 0) {
                // 新建成功清空表单
                this.refetchData()
                this.newWarehouseId = 0
                this.prwId = 0
                this.productRelationidWarehouse = {}
                this.newWarehouseName = ''
                this.ourCode = ''
                this.productName = ''
                this.outCode = ''
                this.outProductName = ''
                this.rule = ''
                this.grossWeight = ''
                toast.success('新建成功')
              } else {
                // 修改成功清空表单
                this.refetchData()
                this.newWarehouseId = 0
                this.prwId = 0
                this.productRelationidWarehouse = {}
                this.newWarehouseName = ''
                this.ourCode = ''
                this.productName = ''
                this.outCode = ''
                this.outProductName = ''
                this.rule = ''
                this.grossWeight = ''
                toast.success('修改成功')
              }
            } else {
              toast.error(res.data.error)
            }
          })
        } else {
          toast.error('商品匹配失败,请查看69码和商品名称是否正确')
        }
      })

    }

    const state = reactive({
      condition: {},
    })

    onMounted(() => {
      state.condition = store.getters['productrelationidwarehouse/getCondition']
    })

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = productrelationidwarehouseUseList()

    return {
      searchList,
      state,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
      advanced_search,
      advanced_search_excel,
      // fromChildren,
      saveRelation,
      changeSelect,
      getWarehouse,
      findWarehouse,
      onUploaded,
      importRelation,
      editRelation,
      delRelation,
      showAddRelation,
      cancelRelation
    }
  },
  created() {
    const userData = getUserData()
    this.user = userData
    if (userData.role_id === 14 || userData.level === "1" || userData.level === "2") {
      this.getWarehouse()
    } else {
      this.findWarehouse()
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
