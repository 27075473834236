import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {getUserData} from "@/auth/utils";

export default function productrelationidwarehouseUseList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'prw_id', label: 'ID', sortable: true },
    // { key: 'warehouse_id', label: '仓库ID', sortable: true },
    { key: 'warehouse_id', label: '仓库名称', sortable: true },
    { key: 'product_id', label: '我方商品ID', sortable: true },
    { key: 'productName', label: '我方商品名称'},
    { key: 'productCode', label: '我方商品69码'},
    // { key: 'out_product_id', label: '外部商品ID', sortable: true },
    { key: 'out_code', label: '外部商品69码', sortable: true },
    { key: 'out_product_name', label: '外部商品名称', sortable: true },
    { key: 'rule', label: '托规', sortable: true },
    { key: 'grossWeight', label: '毛重/g' },
    { key: 'create_time', label: '添加时间', sortable: true },
    { key: 'creator', label: '添加人ID', sortable: true },
    { key: 'update_time', label: '更新时间', sortable: true },
    { key: 'updator', label: '更新人ID', sortable: true },
        { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('prw_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    const condition = {...store.getters['productrelationidwarehouse/getCondition']}
    store
      .dispatch('productrelationidwarehouse/search', {
        search_key: searchKey.value,
        prwId: condition.prwId,
        warehouseId: condition.warehouseId,
        productId: condition.productId,
        code: condition.code,
        productName: condition.productName,
        outProductId: condition.outProductId,
        outProductName: condition.outProductName,
        outCode: condition.outCode,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
      .then(response => {
        if (response.data.code === 0) {
          const data = response.data.data
          const list = data.ext.list
          listTotals.value = data.ext.totals

          callback(list)
        } else {
          toast.error('映射错误')
        }
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '映射列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,

    // Extra Filters
  }
}
